import React, { useEffect, useState } from "react";

const Slide1 = ({ data, links, slideData, gallery }) => {



  const [images, setImages] = useState([])
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  })

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    })
  }

  
  useEffect(() => {
    let imgs = gallery.filter(galleryItem => galleryItem?.page == slideData?.id)
    setImages(imgs)
    
    console.log(imgs, gallery, slideData?.id)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', detectSize)

    return () => {
      window.removeEventListener('resize', detectSize)
    }
  }, [windowDimenion])


  return (
    <div style={{backgroundImage: `url(${windowDimenion.winWidth > 800 ? process.env.REACT_APP_API_URL + slideData?.image : process.env.REACT_APP_API_URL + images?.[0]?.image})`}} className="slide hero">
      <div>
        <h3>{slideData?.title}</h3>
        <h1>Презентация для Вас {data?.user?.full_name}</h1>
        <div dangerouslySetInnerHTML={{__html: slideData?.description}}></div>
        <div dangerouslySetInnerHTML={{__html: slideData?.sub_description}}></div>
        <h2>
          
        </h2>
        <ul className="hot_links">
          {links?.map(link => (
            <>
            {link?.page == slideData?.id && <li><a href={`#page_${link?.order?.id}`}>{link?.name}</a></li>}
            </>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Slide1;
