import React, { useEffect, useState } from 'react'
import Modal from '../Modal'

const SlideCalculate = ({user, slideData}) => {

  const [population, setPopulation] = useState()

  useEffect(() => {
    fetchPopulation()
  }, [user])

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  
  const handleNotification = () => {
    
    setTimeout(openModal, 1000)
    fetch(slideData?.button_link + '?site=presentation&email=' + user?.email, {
      mode: 'no-cors'
    })
    
  }

  
  const fetchPopulation = async () => {
    
    if (user?.city) {
      
      let res = await fetch(process.env.REACT_APP_API_URL + "/city/" + user?.city, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      let data = await res.json();


      setPopulation(data?.population);
      console.log(data);
    }
  };


  return (
    
    <div className="slide">
      <div>
        <h1>{user?.full_name}, для вас расчет по {user?.city}</h1>
        <div className='calculate_info'>
            <div>
                <p>Размер {user?.city} составляет {(population / 1000000).toFixed(3)} млн. человек. Потенциальных клиентов в вашем городе {(population / 117)?.toFixed()} организации. Мы предоставим вам клиентскую базу, по которой вы будете работать. В среднем каждая четвертая организация становиться нашим клиентом и платит по 14000 рублей. Итого емкость рынка {user?.city} составляет {(population / 117 / 4 * 14 / 1000)?.toFixed()} млн. руб.</p>
            </div>
            <div>
                <h3>Потенциальных клиентов в вашем городе</h3>
                <h1>{(population / 117)?.toFixed()}</h1>
                <p>Организации кому интересны услуги Wi-Fi маркетинга</p>
                <h3>Емкость рынка нашей услуги</h3>
                <h1>{(population / 117 / 4 * 14 / 1000)?.toFixed()} млн.руб</h1>
                <p>Организации готовы потратить на наши услуги</p>
            </div>
        </div>
        <h3>
        {slideData?.pre_button_text}
        </h3>
        {slideData?.button_text && (
          <a><button onClick={handleNotification}>{slideData?.button_text}</button></a>
        )}
        
        <Modal modalIsOpen={modalIsOpen} closeModal={closeModal}></Modal>
      </div>
    </div>
  )
}

export default SlideCalculate