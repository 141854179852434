import { Icon } from '@iconify/react';
import React from 'react'
import ReactModal from 'react-modal';

const Modal = ({modalIsOpen, closeModal}) => {

    const customStyles = {
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.75)',
          
          zIndex: 999,
        },
        content: {
          zIndex: 999,
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '40px',
          borderRadius: '20px'
        },
      };


  return (
    <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        bodyOpenClassName="opened"
    >
    <div>
        <Icon width={100} height={100} color="#0a0"  icon="akar-icons:circle-check" />
    </div>

    <p>Уведомление отправлено - мы свяжемся с вами в ближайшее время!</p>
    <button onClick={closeModal}>ОК</button>
    </ReactModal>
  )
}

export default Modal